import Enumeration from 'Lib/utilities/Enumeration';

import ReportCategories from './reportCategories';

const ReportTypes = new Enumeration([
    {
        id: 1,
        name: 'General Ledger',
        url: '/general-ledger',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Details all financial activity, segmented by the chart of accounts.'
    },
    { id: 2, name: 'Tenant Ledger', url: '/tenant-ledger', reportCategoryID: ReportCategories.Accounting, description: '' },
    {
        id: 3,
        name: 'Balance Sheet',
        url: '/balance-sheet',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Reports assets, liabilities, and equity at a specific point in time.'
    },
    {
        id: 4,
        name: 'Expense Distribution',
        url: '/expense-distribution',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Provides a detailed breakdown all expenses recorded in the system during a specified period and the status of payment (amount paid vs. unpaid).'
    },
    { id: 5, name: 'Work Orders', url: '/work-orders', reportCategoryID: ReportCategories.Maintenance, description: '' },
    {
        id: 6,
        name: 'Cash Flow',
        url: '/cash-flow',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Summarizes the amount of cash and cash equivalents entering and leaving a portfolio or property during a specific period of time.'
    },
    {
        id: 7,
        name: 'Cash Flow Annual',
        url: '/cash-flow-annual',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Summarizes the amount of cash and cash equivalents entering and leaving a portfolio or property during an annual period, broken down by month.'
    },
    {
        id: 8,
        name: 'Trial Balance',
        url: '/trial-balance',
        reportCategoryID: ReportCategories.Accounting,
        description:
            'Lists the balances of all general ledger accounts during a certain point in time, including the starting and ending balances, and totals of all debits and credits.'
    },
    { id: 9, name: 'Properties', url: '/properties', reportCategoryID: ReportCategories.Property, description: '' },
    { id: 10, name: 'Units', url: '/units', reportCategoryID: ReportCategories.Property, description: '' },
    { id: 11, name: 'Owners', url: '/owners', reportCategoryID: ReportCategories.Owner, description: '' },
    { id: 12, name: 'Tenants', url: '/tenants', reportCategoryID: ReportCategories.Tenant, description: '' },
    { id: 13, name: 'Vendors', url: '/vendors', reportCategoryID: ReportCategories.Vendor, description: '' },
    { id: 14, name: 'Leases', url: '/leases', reportCategoryID: ReportCategories.Lease, description: '' },
    { id: 15, name: 'Portfolios', url: '/portfolios', reportCategoryID: ReportCategories.Portfolio, description: '' },
    { id: 16, name: 'Portfolio Balances', url: '/portfolio-balances', reportCategoryID: ReportCategories.Portfolio, description: '' },
    {
        id: 17,
        name: 'Cash Flow Detail',
        url: '/cash-flow-detail',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Sums up the amount of cash that enters and leaves a portfolio or property.'
    },
    {
        id: 18,
        name: 'Ledger',
        url: '/ledger',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Contains ledger starting and ending balances, and details of all money in and out during a given period for a specific portfolio or property.'
    },
    { id: 19, name: 'Listings', url: '/listings', reportCategoryID: ReportCategories.Property, description: '' },
    { id: 20, name: 'Appliances', url: '/appliances', reportCategoryID: ReportCategories.Property, description: '' },
    { id: 21, name: 'Vendor Ledger', url: '/vendor-ledger', reportCategoryID: ReportCategories.Vendor, description: '' },
    {
        id: 22,
        name: 'Check Register',
        url: '/check-register',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Shows details of all outbound bank account activity during a specified period.'
    },
    {
        id: 23,
        name: 'Deposit Register',
        url: '/deposit-register',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Lists all deposits and positive settlements recorded to a bank account during a specified period.'
    },
    {
        id: 24,
        name: 'Bank Ledger',
        url: '/bank-ledger',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Contains the account summary, including details of all money in and out during a given period.'
    },
    {
        id: 25,
        name: 'Settlements',
        url: '/settlements',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Provides a summary of electronic transactions settled to your bank account.'
    },
    {
        id: 26,
        name: 'Ledger Summary',
        url: '/ledger-summary',
        reportCategoryID: ReportCategories.Accounting,
        description:
            'Contains ledger starting and ending balances, and a summary indexed by the Chart of Accounts of all money in and out during a given period for a specific portfolio or property.'
    },
    { id: 27, name: 'Unit Management Fees', url: '/unit-management-fees', reportCategoryID: ReportCategories.Accounting, description: '' },
    { id: 28, name: 'Lease Balances', key: 'LeaseBalance', url: '/lease-balances', reportCategoryID: ReportCategories.Lease, description: '' },
    {
        id: 29,
        name: 'Sales Tax',
        url: '/sales-tax',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Provides a summary of tax liabilities collected and owned to tax authorities.'
    },
    { id: 30, name: 'Vacancy', url: '/vacancy', reportCategoryID: ReportCategories.Property, description: '' },
    { id: 31, name: 'Lease Charges', url: '/lease-charges', reportCategoryID: ReportCategories.Lease, description: '' },
    { id: 32, name: 'Owner Unit', url: '/owner-unit', reportCategoryID: ReportCategories.Portfolio, description: '' },
    { id: 33, name: 'Lease Recurring Charges', url: '/lease-recurring-charges', reportCategoryID: ReportCategories.Lease, description: '' },
    {
        id: 34,
        key: 'LeasePayments',
        name: 'Tenant Receipts',
        url: '/lease-payments',
        reportCategoryID: ReportCategories.Lease,
        description: 'Details individual incoming tenant payments (tenant receipts) during a specific period of time.'
    },
    {
        id: 35,
        name: 'Payables',
        url: '/payables',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Detailed breakdown of all invoices and liabilities for a selected period of time, as well as the payment status of each.'
    },
    {
        id: 36,
        name: 'Bank Balance Breakdown',
        url: '/bank-balance-breakdown',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Provides a detailed breakdown of the balances in the company’s bank accounts.'
    },
    { id: 37, name: 'Note', url: '/note', reportCategoryID: ReportCategories.General, description: '' },
    {
        id: 38,
        name: 'Budget',
        url: '/budget',
        reportCategoryID: ReportCategories.Accounting,
        description: 'A financial picture of a portfolio or property over a specific period of time.'
    },
    {
        id: 39,
        name: 'Deposits',
        url: '/deposits',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Lists all deposits manually recorded to a bank account during a specified period.'
    },
    { id: 40, name: 'Property Group', url: '/property-group', reportCategoryID: ReportCategories.Property, description: '' },
    {
        id: 41,
        name: 'Chart Of Accounts',
        url: '/account',
        reportCategoryID: ReportCategories.Accounting,
        description: 'An index of all financial accounts for recording and categorizing financial transactions into a general ledger.'
    },
    { id: 42, name: 'Emails', url: '/emails', reportCategoryID: ReportCategories.General, description: '' },
    { id: 43, name: 'Lease Recurring Credits', url: '/lease-recurring-credits', reportCategoryID: ReportCategories.Lease, description: '' },
    {
        id: 44,
        name: 'Recurring Bills',
        url: '/recurring-bills',
        reportCategoryID: ReportCategories.Accounting,
        description:
            'Provides a summary of regular expenses that are scheduled to automatically reoccur periodically over time, typically on a monthly, quarterly, or annual basis.'
    },
    {
        id: 45,
        name: 'Recurring Journal Entries',
        url: '/recurring-journal-entries',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Details all journal entries scheduled to automatically reoccur periodically over time, typically on a monthly, quarterly, or annual basis.'
    },
    {
        id: 46,
        name: 'Recurring Ledger Transfers',
        url: '/recurring-ledger-transfers',
        reportCategoryID: ReportCategories.Accounting,
        description:
            'Provides details about transfers of funds between portfolio or property ledgers scheduled to automatically reoccur periodically over time, typically on a monthly, quarterly, or annually basis.'
    },
    { id: 47, name: 'Recurring Management Fees', url: '/recurring-management-fees', reportCategoryID: ReportCategories.Accounting, description: '' },
    { id: 48, name: 'Screening Payments', url: '/screening-payments', reportCategoryID: ReportCategories.Screening, description: '' },
    { id: 49, name: 'Screening Payouts', url: '/screening-payouts', reportCategoryID: ReportCategories.Screening, description: '' },
    { id: 50, name: 'Screening Refunds', url: '/screening-refunds', reportCategoryID: ReportCategories.Screening, description: '' },
    { id: 51, name: 'Associations', url: '/associations', reportCategoryID: ReportCategories.Property, description: '' },
    {
        id: 52,
        name: 'Aged Receivables',
        url: '/aged-receivables',
        reportCategoryID: ReportCategories.Accounting,
        description: 'An overview of unpaid charges due from customers and how long they have been outstanding.'
    },
    {
        id: 53,
        name: 'Income Statement',
        url: '/income-statement',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Provides a summary of income, expenses, and net operating income or loss over a specific period.'
    },
    {
        id: 54,
        name: 'Income Statement Annual',
        url: '/income-statement-annual',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Provides a summary of income, expenses, and net operating income or loss of a property on an annual basis, broken down by month.'
    },
    {
        id: 55,
        name: 'Income Statement Property Comparison',
        url: '/income-statement-property-comparison',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Provides a summary comparison between properties of income, expenses, and net operating income or loss during a specific period.'
    },
    { id: 56, name: 'Security Deposits', key: 'LeaseSecurityDepositBalance', url: '/lease-security-deposit-balances', reportCategoryID: ReportCategories.Lease, description: '' },
    { id: 57, name: 'Prepayments', key: 'LeasePrepaymentBalance', url: '/lease-prepayment-balances', reportCategoryID: ReportCategories.Lease, description: '' },
    {
        id: 58,
        name: 'Aged Payables',
        url: '/aged-payables',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Summarizes the outstanding (unpaid) bills owed, broken down by vendor and due date.'
    },
    {
        id: 59,
        name: 'Cash Flow Property Comparison',
        url: '/cash-flow-property-comparison',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Provides a comparative analysis of the cash flow performance of multiple properties within a portfolio.'
    },
    { id: 60, name: 'Applications', url: '/applications', reportCategoryID: ReportCategories.Screening, description: '' },
    { id: 61, name: 'Applicants', url: '/applicants', reportCategoryID: ReportCategories.Screening, description: '' },
    { id: 62, name: 'Vendor Payment', url: '/vendor-payment', reportCategoryID: ReportCategories.Vendor, description: '' },
    { id: 63, name: 'Rent Roll', url: '/rent-roll', reportCategoryID: ReportCategories.Lease, description: '' },
    { id: 64, name: 'Inspections', url: '/inspections', reportCategoryID: ReportCategories.Maintenance, description: '' },
    { id: 65, name: 'Lease Tenants', url: '/lease-tenants', reportCategoryID: ReportCategories.Lease, description: '' },
    { id: 66, name: 'Lease Credits', url: '/lease-credits', reportCategoryID: ReportCategories.Lease, description: '' },
    {
        id: 67,
        key: 'OwnerPayments',
        name: 'Owner Receipts',
        url: '/owner-payments',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Details individual owner contributions (owner receipts) during a specific period of time.'
    },
    {
        id: 68,
        key: 'OtherPayments',
        name: 'Other Receipts',
        url: '/other-payments',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Details individual incoming payments recorded through Other Receipts (ie. non-owner or non-tenant receipts) during a specific period of time.'
    },
    { id: 69, name: 'Maintenance Categories', url: '/maintenance-categories', reportCategoryID: ReportCategories.Vendor, description: '' },
    { id: 70, name: 'Vendor Payment Summary', url: '/vendor-payment-summary', reportCategoryID: ReportCategories.Vendor, description: '' },
    { id: 71, name: 'Multiple Vendor Payment', url: '/multiple-vendor-payment', reportCategoryID: ReportCategories.Vendor, description: '' },
    {
        id: 72,
        name: 'Recurring Bills Detail',
        url: '/recurring-bills-detail',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Provides details of regular expenses that are scheduled to automatically reoccur periodically over time, typically on a monthly, quarterly, or annual basis.'
    },
    { id: 73, name: 'RentSign Status', url: '/rentsign-status', reportCategoryID: ReportCategories.RentSign, description: '' },
    { id: 74, name: 'RentSign Detail Status', url: '/rentsign-detail-status', reportCategoryID: ReportCategories.RentSign, description: '' },
    { id: 75, name: 'Vendor Detail Rating', url: '/vendor-detail-rating', reportCategoryID: ReportCategories.Vendor, description: '' },
    { id: 76, name: 'Vendor Summary Rating', url: '/vendor-summary-rating', reportCategoryID: ReportCategories.Vendor, description: '' },
    {
        id: 77,
        name: 'Cash Flow Annual (per property)',
        key: 'CashFlowAnnualPerProperty',
        url: '/cash-flow-annual-per-property',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Summarizes the amount of cash and cash equivalents entering and leaving a property during an annual period, broken down by month.'
    },
    {
        id: 78,
        name: 'Cash Flow (per property)',
        key: 'CashFlowPerProperty',
        url: '/cash-flow-per-property',
        reportCategoryID: ReportCategories.Accounting,
        description:
            'Side-by-side comparison of all properties (or those within a specified portfolio), summarizing the cash and cash equivalents entering and leaving a property during a specific period of time.'
    },
    {
        id: 79,
        name: 'Income Statement Annual (per property)',
        key: 'IncomeStatementAnnualPerProperty',
        url: '/income-statement-annual-per-property',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Provides a summary of income, expenses, and net operating income or loss on an annual basis, broken down by month.'
    },
    {
        id: 80,
        name: 'Income Statement (per property)',
        key: 'IncomeStatementPerProperty',
        url: '/income-statement-per-property',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Provides a summary of income, expenses, and net operating income or loss of a property during a specific period of time.'
    },
    { id: 81, name: 'Scheduled Reports', url: '/scheduledreport-reports', reportCategoryID: ReportCategories.General, description: '' },
    {
        id: 82,
        name: 'Balance Sheet Portfolio Comparison',
        url: '/balance-sheet-portfolio-comparison',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Side-by-side comparison of assets, liabilities, and equity at a specific point in time across all portfolios.'
    },
    {
        id: 83,
        name: 'Balance Sheet Property Comparison',
        url: '/balance-sheet-property-comparison',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Side-by-side comparison of assets, liabilities, and equity at a specific point in time across properties.'
    },
    {
        id: 84,
        name: 'Balance Sheet Unit Comparison',
        url: '/balance-sheet-unit-comparison',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Side-by-side comparison of assets, liabilities, and equity at a specific point in time across units.'
    },
    {
        id: 85,
        name: 'Income Statement Portfolio Comparison',
        url: '/income-statement-portfolio-comparison',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Provides a summary comparison between portfolios of income, expenses, and net operating income or loss during a specific period.'
    },
    {
        id: 86,
        name: 'Income Statement Detail',
        url: '/income-statement-detail',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Details individual transactions making up the income, expenses, and net operating income or loss over a specific period.'
    },
    {
        id: 87,
        name: 'Income Statement Detail (per property)',
        key: 'IncomeStatementPerPropertyDetail',
        url: '/income-statement-per-property-detail',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Details individual transactions making up the income, expenses, and net operating income or loss of a property during a specific period.'
    },
    {
        id: 95,
        name: 'Bank Register',
        key: 'BankRegister',
        url: '/bank-register',
        reportCategoryID: ReportCategories.Accounting,
        description:
            'Details all bank transactions and the individual items that make up each transaction during a given period of time, as well as the reconciliation status of each.'
    },
    { id: 96, name: 'Balance Sheet Detail', url: '/balance-sheet-detail', reportCategoryID: ReportCategories.Accounting, description: '' },
    { id: 97, name: 'Balance Sheet Detail Per Ledger', url: '/balance-sheet-detail-per-ledger', reportCategoryID: ReportCategories.Accounting, description: '' },
    {
        id: 98,
        name: 'Property Budgets',
        key: 'PropertyBudgets',
        url: '/property-budgets',
        reportCategoryID: ReportCategories.Accounting,
        description:
            'Detail budget report for all CoA denoted as Budget Enabled'
    },
    {
        id: 99,
        name: 'Property Budgets VS Actuals',
        key: 'PropertyBudgetsVSActuals',
        url: '/property-budgets-vs-actuals',
        reportCategoryID: ReportCategories.Accounting,
        description:
            'Detail Budgets vs. Actuals all CoA denoted as Budget Enabled'
    },
    {
        id: 100,
        name: 'Alert',
        key: 'Alert',
        url: '/alerts',
        reportCategoryID: ReportCategories.General,
        description:
            'Alert Report by type '
    },
    {
        id: 101,
        name: 'Property Budget Per Property Report',
        key: 'PropertyBudgetPerProperty',
        url: '/property-budget-per-property',
        reportCategoryID: ReportCategories.Accounting,
        description:'Property Budget (per Property) for all CoA denoted as Budget Enabled'
    },
    {
        id: 102,
        name: 'Property Budgets VS Actuals Per Property Report',
        key: 'PropertyBudgetsVSActualsPerProperty',
        url: '/property-budgets-vs-actuals-per-property',
        reportCategoryID: ReportCategories.Accounting,
        description:'Detail Budgets vs. Actuals all CoA (per Property) denoted as Budget Enabled'
    },
    {
        id: 103,
        name: 'Settlement Detail Report',
        key: 'SettlementDetail',
        url: '/settlement-detail',
        reportCategoryID: ReportCategories.Accounting,
        description: 'Provides the detail breakdown of the Money In / Out Settlement.'
    },
    {
        id: 104,
        name: 'Assignment',
        key: 'Assignment',
        url: '/assignments',
        reportCategoryID: ReportCategories.General,
        description:
            'Assignment Diagnostic Report by type '
    },
    {
        id: 105,
        name: 'Cash Suspect Report',
        key: 'CashSuspect',
        url: '/cash-suspect',
        reportCategoryID: ReportCategories.Accounting,
        description:
            'Shows Portfolios that are suspect in 1 of 2 ways; has funds in a bank account that is not assigned as its default /  balances are incorrect between the Operating and Security Deposit account.'
           
    }


]);



export const getReportCategoryName = reportTypeID => {
    const reportCategoryID = ReportTypes.getProperty(reportTypeID, 'reportCategoryID', ReportCategories.General);

    return ReportCategories.getName(reportCategoryID);
};

export const getUrl = reportTypeID => {
    return ReportTypes.getProperty(reportTypeID, 'url');
};

export const getDescription = reportTypeID => {
    return ReportTypes.getProperty(reportTypeID, 'description');
};

export const PortfolioReportTypes = new Enumeration([
    { id: 15, name: 'Portfolios' },
    { id: 16, name: 'Portfolio Balances' },
    { id: 11, name: 'Owners' },
    { id: 32, name: 'Owner Unit' }
]);

export const PropertyReportTypes = new Enumeration([
    { id: 9, name: 'Properties' },
    { id: 10, name: 'Units' },
    { id: 19, name: 'Listings' },
    { id: 20, name: 'Appliances' },
    { id: 30, name: 'Vacancy' },
    { id: 40, name: 'Property Group' },
    { id: 51, name: 'Associations' }
  
]);

export const LeaseReportTypes = new Enumeration([
    { id: 14, name: 'Leases' },
    { id: 28, name: 'Lease Balances' },
    { id: 31, name: 'Lease Charges' },
    { id: 33, name: 'Lease Recurring Charges' },
    { id: 34, key: 'LeasePayments', name: 'Tenant Receipts' },
    { id: 43, name: 'Lease Recurring Credits' },
    { id: 56, name: 'Security Deposits' },
    { id: 57, name: 'Prepayments' },
    { id: 63, name: 'Rent Roll' },
    { id: 65, name: 'Lease Tenants' },
    { id: 66, name: 'Lease Credits' }
]);

export const TenantReportTypes = new Enumeration([
    { id: 2, name: 'Tenant Ledger' },
    { id: 12, name: 'Tenants' }
]);

export const VendorReportTypes = new Enumeration([
    { id: 13, name: 'Vendors' },
    { id: 21, name: 'Vendor Ledger' },
    { id: 62, name: 'Vendor Payment' },
    { id: 69, name: 'Maintenance Categories' },
    { id: 70, name: 'Vendor Payment Summary' },
    { id: 71, name: 'Multiple Vendor Payment' },
    { id: 75, name: 'Vendor Detail Rating' },
    { id: 76, name: 'Vendor Summary Rating' }
]);

export const AccountingReportTypes = new Enumeration([
    { id: 1, name: 'General Ledger' },
    { id: 3, name: 'Balance Sheet' },
    { id: 82, name: 'Balance Sheet Portfolio Comparison' },
    { id: 83, name: 'Balance Sheet Property Comparison' },
    { id: 84, name: 'Balance Sheet Unit Comparison' },
    { id: 4, name: 'Expense Distribution' },
    { id: 6, name: 'Cash Flow' },
    { id: 78, name: 'Cash Flow (per property)' },
    { id: 7, name: 'Cash Flow Annual' },
    { id: 77, name: 'Cash Flow Annual (per property)' },
    { id: 59, name: 'Cash Flow Property Comparison' },
    { id: 8, name: 'Trial Balance' },
    { id: 17, name: 'Cash Flow Detail' },
    { id: 18, name: 'Ledger' },
    { id: 26, name: 'Ledger Summary' },
    { id: 22, name: 'Check Register' },
    { id: 23, name: 'Deposit Register' },
    { id: 24, name: 'Bank Ledger' },
    { id: 25, name: 'Settlements' },
    { id: 27, name: 'Unit Management Fees' },
    { id: 35, name: 'Payables' },
    { id: 36, name: 'Bank Balance Breakdown' },
    { id: 38, name: 'Budget' },
    { id: 29, name: 'Sales Tax' },
    { id: 39, name: 'Deposits' },
    { id: 41, name: 'Chart Of Accounts' },
    { id: 44, name: 'Recurring Bills' },
    { id: 45, name: 'Recurring Journal Entries' },
    { id: 46, name: 'Recurring Ledger Transfers' },
    { id: 47, name: 'Recurring Management Fees' },
    { id: 52, name: 'Aged Receivables' },
    { id: 58, name: 'Aged Payables' },
    { id: 53, name: 'Income Statement' },
    { id: 80, name: 'Income Statement (per property)' },
    { id: 54, name: 'Income Statement Annual' },
    { id: 79, name: 'Income Statement Annual (per property)' },
    { id: 85, name: 'Income Statement Portfolio Comparison' },
    { id: 55, name: 'Income Statement Property Comparison' },
    { id: 67, key: 'OwnerPayments', name: 'Owner Receipts' },
    { id: 68, key: 'OtherPayments', name: 'Other Receipts' },
    { id: 72, name: 'Recurring Bills Detail' },
    { id: 86, name: 'Income Statement Detail' },
    { id: 87, name: 'Income Statement Detail (per property)' },

    { id: 95, name: 'Oregon Bank Report' },
    { id: 96, name: 'Balance Sheet Detail' },
    { id: 97, name: 'Balance Sheet Detail Per Ledger' },
    { id: 98, name: 'Property Budgets' },
    { id: 99, name: 'Property Budgets VS Actuals'},
    { id: 101, name: 'Property Budget (per property)' },
    { id: 103, name: 'Settlement Detail' },
    { id: 105, name: 'Cash Suspect Report'}
]);

export const MaintenanceReportTypes = new Enumeration([
    { id: 5, name: 'Work Orders' },
    { id: 64, name: 'Inspections' }
]);

export const StatementReports = new Enumeration([
    { id: 6, name: 'Cash Flow' },
    { id: 78, name: 'Cash Flow (per property)' },
    { id: 7, name: 'Cash Flow Annual' },
    { id: 77, name: 'Cash Flow Annual (per property)' },
    { id: 17, name: 'Cash Flow Detail' },
    { id: 59, name: 'Cash Flow Property Comparison' },
    { id: 53, name: 'Income Statement' },
    { id: 80, name: 'Income Statement (per property)' },
    { id: 54, name: 'Income Statement Annual' },
    { id: 79, name: 'Income Statement Annual (per property)' },
    { id: 55, name: 'Income Statement Property Comparison' },
    { id: 5, name: 'Work Orders' },
    { id: 4, name: 'Expense Distribution' },
    { id: 14, name: 'Leases' },
    { id: 35, name: 'Payables' },
    { id: 18, name: 'Ledger' }
]);

export const GeneralReportTypes = new Enumeration([
    { id: 37, name: 'Note' },
    { id: 42, name: 'Emails' },
    { id: 81, name: 'Scheduled Reports' },
    { id: 100, name: 'Alert' },
    { id: 104, name: 'Assignment' }
]);

export const RentSignReportTypes = new Enumeration([
    { id: 73, name: 'RentSign Status' },
    { id: 74, name: 'RentSign Detail Status' }
]);

export const ScreeningReportTypes = new Enumeration([
    { id: 48, name: 'Screening Payments' },
    { id: 49, name: 'Screening Payouts' },
    { id: 50, name: 'Screening Refunds' },
    { id: 60, name: 'Applications' },
    { id: 61, name: 'Applicants' }
]);

export const HideInBillMode = new Enumeration([
    { id: 27, name: 'Unit Management Fees' },
    { id: 47, name: 'Recurring Management Fees' }
]);

export default ReportTypes;
