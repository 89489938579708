import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/reports',
        component: Loader(() => import('~/containers/reports/Index'))
    },
    {
        path: '/reports/scheduled-reports',
        component: Loader(() => import('~/containers/reports/scheduledReports/List'))
    },
    {
        path: '/reports/scheduled-reports/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/scheduledReports/View'))
    },
    {
        path: '/reports/balance-sheet',
        component: Loader(() => import('~/containers/reports/BalanceSheet'))
    },
    {
        path: '/reports/balance-sheet/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/BalanceSheet'))
    },

    {
        path: '/reports/tenant-ledger',
        component: Loader(() => import('~/containers/reports/TenantLedger'))
    },
    {
        path: '/reports/tenant-ledger/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/TenantLedger'))
    },

    {
        path: '/reports/tenants',
        component: Loader(() => import('~/containers/reports/Tenants'))
    },
    {
        path: '/reports/tenants/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/Tenants'))
    },

    {
        path: '/reports/general-ledger',
        component: Loader(() => import('~/containers/reports/GeneralLedger'))
    },
    {
        path: '/reports/general-ledger/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/GeneralLedger'))
    },

    {
        path: '/reports/work-orders',
        component: Loader(() => import('~/containers/reports/WorkOrders'))
    },
    {
        path: '/reports/work-orders/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/WorkOrders'))
    },

    {
        path: '/reports/trial-balance',
        component: Loader(() => import('~/containers/reports/TrialBalance'))
    },
    {
        path: '/reports/trial-balance/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/TrialBalance'))
    },

    {
        path: '/reports/cash-flow',
        component: Loader(() => import('~/containers/reports/CashFlow'))
    },
    {
        path: '/reports/cash-flow/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/CashFlow'))
    },

    {
        path: '/reports/cash-flow-per-property',
        component: Loader(() => import('~/containers/reports/CashFlowPerProperty'))
    },
    {
        path: '/reports/cash-flow-per-property/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/CashFlowPerProperty'))
    },

    {
        path: '/reports/cash-flow-annual',
        component: Loader(() => import('~/containers/reports/CashFlowAnnual'))
    },
    {
        path: '/reports/cash-flow-annual/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/CashFlowAnnual'))
    },

    {
        path: '/reports/cash-flow-annual-per-property',
        component: Loader(() => import('~/containers/reports/CashFlowAnnualPerProperty'))
    },
    {
        path: '/reports/cash-flow-annual-per-property/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/CashFlowAnnualPerProperty'))
    },

    {
        path: '/reports/cash-flow-detail',
        component: Loader(() => import('~/containers/reports/CashFlowDetail'))
    },
    {
        path: '/reports/cash-flow-detail/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/CashFlowDetail'))
    },

    {
        path: '/reports/expense-distribution',
        component: Loader(() => import('~/containers/reports/ExpenseDistribution'))
    },
    {
        path: '/reports/expense-distribution/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/ExpenseDistribution'))
    },

    {
        path: '/reports/properties',
        component: Loader(() => import('~/containers/reports/Properties'))
    },
    {
        path: '/reports/properties/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/Properties'))
    },

    {
        path: '/reports/owners',
        component: Loader(() => import('~/containers/reports/Owners'))
    },
    {
        path: '/reports/owners/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/Owners'))
    },

    {
        path: '/reports/listings',
        component: Loader(() => import('~/containers/reports/Listings'))
    },
    {
        path: '/reports/listings/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/Listings'))
    },

    {
        path: '/reports/appliances',
        component: Loader(() => import('~/containers/reports/Appliances'))
    },
    {
        path: '/reports/appliances/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/Appliances'))
    },

    {
        path: '/reports/ledger',
        component: Loader(() => import('~/containers/reports/Ledger'))
    },
    {
        path: '/reports/ledger/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/Ledger'))
    },

    {
        path: '/reports/ledger-summary',
        component: Loader(() => import('~/containers/reports/LedgerSummary'))
    },
    {
        path: '/reports/ledger-summary/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/LedgerSummary'))
    },

    {
        path: '/reports/leases',
        component: Loader(() => import('~/containers/reports/Leases'))
    },
    {
        path: '/reports/leases/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/Leases'))
    },

    {
        path: '/reports/units',
        component: Loader(() => import('~/containers/reports/Units'))
    },
    {
        path: '/reports/units/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/Units'))
    },

    {
        path: '/reports/portfolios',
        component: Loader(() => import('~/containers/reports/Portfolios'))
    },
    {
        path: '/reports/portfolios/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/Portfolios'))
    },

    {
        path: '/reports/portfolio-balances',
        component: Loader(() => import('~/containers/reports/PortfolioBalances'))
    },
    {
        path: '/reports/portfolio-balances/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/PortfolioBalances'))
    },

    {
        path: '/reports/vendors',
        component: Loader(() => import('~/containers/reports/Vendors'))
    },
    {
        path: '/reports/vendors/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/Vendors'))
    },
    {
        path: '/reports/vendor-ledger',
        component: Loader(() => import('~/containers/reports/VendorLedger'))
    },
    {
        path: '/reports/maintenance-categories',
        component: Loader(() => import('~/containers/reports/MaintenanceCategories'))
    },
    {
        path: '/reports/scheduled-reports',
        component: Loader(() => import('~/containers/reports/scheduledReports/List'))
    },
    {
        path: '/reports/check-register',
        component: Loader(() => import('~/containers/reports/CheckRegister'))
    },
    {
        path: '/reports/deposit-register',
        component: Loader(() => import('~/containers/reports/DepositRegister'))
    },
    {
        path: '/reports/bank-ledger',
        component: Loader(() => import('~/containers/reports/BankLedger'))
    },
    {
        path: '/reports/settlements',
        component: Loader(() => import('~/containers/reports/Settlements'))
    },
    {
        path: '/reports/unit-management-fees',
        component: Loader(() => import('~/containers/reports/UnitManagementFees'))
    },
    {
        path: '/reports/unit-management-fees/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/UnitManagementFees'))
    },
    {
        path: '/reports/lease-balances',
        component: Loader(() => import('~/containers/reports/LeaseBalances'))
    },
    {
        path: '/reports/lease-balances/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/LeaseBalances'))
    },
    {
        path: '/reports/lease-security-deposit-balances',
        component: Loader(() => import('~/containers/reports/LeaseSecurityDepositBalances'))
    },
    {
        path: '/reports/lease-security-deposit-balances/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/LeaseSecurityDepositBalances'))
    },
    {
        path: '/reports/lease-prepayment-balances',
        component: Loader(() => import('~/containers/reports/LeasePrepaymentBalances'))
    },
    {
        path: '/reports/lease-prepayment-balances/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/LeasePrepaymentBalances'))
    },
    {
        path: '/reports/lease-charges',
        component: Loader(() => import('~/containers/reports/LeaseCharges'))
    },
    {
        path: '/reports/lease-charges/:id([0-9]+)',
        component: Loader(() => import('~/containers/reports/LeaseCharges'))
    },
    {
        path: '/reports/vacancy',
        component: Loader(() => import('~/containers/reports/Vacancy'))
    },
    {
        path: '/reports/owner-unit',
        component: Loader(() => import('~/containers/reports/OwnerUnit'))
    },
    {
        path: '/reports/lease-recurring-charges',
        component: Loader(() => import('~/containers/reports/LeaseRecurringCharges'))
    },
    {
        path: '/reports/lease-recurring-credits',
        component: Loader(() => import('~/containers/reports/LeaseRecurringCredits'))
    },
    {
        path: '/reports/lease-payments',
        component: Loader(() => import('~/containers/reports/LeasePayments'))
    },
    {
        path: '/reports/lease-credits',
        component: Loader(() => import('~/containers/reports/LeaseCredits'))
    },
    {
        path: '/reports/payables',
        component: Loader(() => import('~/containers/reports/Payables'))
    },
    {
        path: '/reports/note',
        component: Loader(() => import('~/containers/reports/Note'))
    },
    {
        path: '/reports/bank-balance-breakdown',
        component: Loader(() => import('~/containers/reports/BankBalanceBreakdown'))
    },
    {
        path: '/reports/budget',
        component: Loader(() => import('~/containers/reports/Budget'))
    },
    {
        path: '/reports/sales-tax',
        component: Loader(() => import('~/containers/reports/SalesTax'))
    },
    {
        path: '/reports/deposits',
        component: Loader(() => import('~/containers/reports/Deposits'))
    },
    {
        path: '/reports/property-group',
        component: Loader(() => import('~/containers/reports/PropertyGroup'))
    },
    {
        path: '/reports/account',
        component: Loader(() => import('~/containers/reports/ChartOfAccounts'))
    },
    {
        path: '/reports/emails',
        component: Loader(() => import('~/containers/reports/Emails'))
    },
    {
        path: '/reports/recurring-bills',
        component: Loader(() => import('~/containers/reports/RecurringBills'))
    },
    {
        path: '/reports/recurring-journal-entries',
        component: Loader(() => import('~/containers/reports/RecurringJournalEntries'))
    },
    {
        path: '/reports/recurring-ledger-transfers',
        component: Loader(() => import('~/containers/reports/RecurringLedgerTransfers'))
    },
    {
        path: '/reports/recurring-management-fees',
        component: Loader(() => import('~/containers/reports/RecurringManagementFees'))
    },
    {
        path: '/reports/screening-payments',
        component: Loader(() => import('~/containers/reports/ScreeningPayments'))
    },
    {
        path: '/reports/screening-payouts',
        component: Loader(() => import('~/containers/reports/ScreeningPayouts'))
    },
    {
        path: '/reports/screening-refunds',
        component: Loader(() => import('~/containers/reports/ScreeningRefunds'))
    },
    {
        path: '/reports/associations',
        component: Loader(() => import('~/containers/reports/Associations'))
    },
    {
        path: '/reports/aged-receivables',
        component: Loader(() => import('~/containers/reports/AgedReceivables'))
    },
    {
        path: '/reports/aged-payables',
        component: Loader(() => import('~/containers/reports/AgedPayables'))
    },
    {
        path: '/reports/income-statement',
        component: Loader(() => import('~/containers/reports/IncomeStatement'))
    },
    {
        path: '/reports/income-statement-per-property',
        component: Loader(() => import('~/containers/reports/IncomeStatementPerProperty'))
    },
    {
        path: '/reports/income-statement-annual',
        component: Loader(() => import('~/containers/reports/IncomeStatementAnnual'))
    },
    {
        path: '/reports/income-statement-annual-per-property',
        component: Loader(() => import('~/containers/reports/IncomeStatementAnnualPerProperty'))
    },
    {
        path: '/reports/income-statement-portfolio-comparison',
        component: Loader(() => import('~/containers/reports/IncomeStatementPortfolioComparison'))
    },
    {
        path: '/reports/income-statement-property-comparison',
        component: Loader(() => import('~/containers/reports/IncomeStatementPropertyComparison'))
    },
    {
        path: '/reports/cash-flow-property-comparison',
        component: Loader(() => import('~/containers/reports/CashFlowPropertyComparison'))
    },
    {
        path: '/reports/applications',
        component: Loader(() => import('~/containers/reports/Applications'))
    },
    {
        path: '/reports/applicants',
        component: Loader(() => import('~/containers/reports/Applicants'))
    },
    {
        path: '/reports/vendor-payment',
        component: Loader(() => import('~/containers/reports/VendorPayment'))
    },
    {
        path: '/reports/rent-roll',
        component: Loader(() => import('~/containers/reports/RentRoll'))
    },
    {
        path: '/reports/inspections',
        component: Loader(() => import('~/containers/reports/Inspections'))
    },
    {
        path: '/reports/lease-tenants',
        component: Loader(() => import('~/containers/reports/LeaseTenants'))
    },
    {
        path: '/reports/owner-payments',
        component: Loader(() => import('~/containers/reports/OwnerPayments'))
    },
    {
        path: '/reports/other-payments',
        component: Loader(() => import('~/containers/reports/OtherPayments'))
    },
    {
        path: '/reports/vendor-payment-summary',
        component: Loader(() => import('~/containers/reports/VendorPaymentSummary'))
    },
    {
        path: '/reports/multiple-vendor-payment',
        component: Loader(() => import('~/containers/reports/MultipleVendorPayment'))
    },
    {
        path: '/reports/recurring-bills-detail',
        component: Loader(() => import('~/containers/reports/RecurringBillsDetail'))
    },
    {
        path: '/reports/rentsign-status',
        component: Loader(() => import('~/containers/reports/RentSignStatusReport'))
    },
    {
        path: '/reports/rentsign-detail-status',
        component: Loader(() => import('~/containers/reports/RentSignDetailStatusReport'))
    },
    {
        path: '/reports/vendor-detail-rating',
        component: Loader(() => import('~/containers/reports/VendorDetailRatingReport'))
    },
    {
        path: '/reports/vendor-summary-rating',
        component: Loader(() => import('~/containers/reports/VendorSummaryRatingReport'))
    },
    {
        path: '/reports/scheduledreport-reports',
        component: Loader(() => import('~/containers/reports/ScheduledReports'))
    },
    {
        path: '/reports/balance-sheet-portfolio-comparison',
        component: Loader(() => import('~/containers/reports/BalanceSheetPortfolioComparison'))
    },
    {
        path: '/reports/balance-sheet-property-comparison',
        component: Loader(() => import('~/containers/reports/BalanceSheetPropertyComparison'))
    },
    {
        path: '/reports/balance-sheet-unit-comparison',
        component: Loader(() => import('~/containers/reports/BalanceSheetUnitComparison'))
    },
    {
        path: '/reports/income-statement-detail',
        component: Loader(() => import('~/containers/reports/IncomeStatementDetail'))
    },
    {
        path: '/reports/income-statement-per-property-detail',
        component: Loader(() => import('~/containers/reports/IncomeStatementPerPropertyDetail'))
    },

    {
        path: '/reports/bank-register',
        component: Loader(() => import('~/containers/reports/BankRegister'))
    },
    {
        path: '/reports/balance-sheet-detail',
        component: Loader(() => import('~/containers/reports/BalanceSheetDetail'))
    },
    {
        path: '/reports/balance-sheet-detail-per-ledger',
        component: Loader(() => import('~/containers/reports/BalanceSheetDetailPerLedger'))
    },
    {
        path: '/reports/property-budgets',
        component: Loader(() => import('~/containers/reports/PropertyBudgets'))
    },
    {
        path: '/reports/property-budgets-vs-actuals',
        component: Loader(() => import('~/containers/reports/PropertyBudgetsVSActuals'))
    },
    {
        path: '/reports/alerts',
        component: Loader(() => import('~/containers/reports/Alerts'))
    },
    {
        path: '/reports/property-budget-per-property',
        component: Loader(() => import('~/containers/reports/PropertyBudgetPerProperty'))
    },
    {
        path: '/reports/property-budgets-vs-actuals-per-property',
        component: Loader(() => import('~/containers/reports/PropertyBudgetsVSActualsPerProperty'))
    },
    {
        path: '/reports/settlement-detail',
        component: Loader(() => import('~/containers/reports/SettlementDetail'))
    },
    {
        path: '/reports/assignments',
        component: Loader(() => import('~/containers/reports/Assignments'))
    },
    {
        path: '/reports/cash-suspect',
        component: Loader(() => import('~/containers/reports/CashSuspect'))
    }
];

export default routes;
