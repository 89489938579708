import { useSelector } from 'react-redux';
import { Container, Typography } from '@mui/material';

export default function CurrentAccount() {
    const app = useSelector(state => state.app);

    return (
        <Container
            sx={theme => ({
                position: 'sticky',
                bottom: 0,
                margin: '0 1rem 22px 1rem',
                width: 'auto',
                borderRadius: '12px',
                padding: 4,
                backgroundColor: theme.palette.common.white,
                boxShadow: '0px 1px 2px #B6C7D6'
            })}
        >
            <Typography sx={{ whiteSpace: 'normal' }}>{app.account.name}</Typography>
            {(process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'qa' || process.env.NODE_ENV == 'staging') && (
                <Typography sx={{ fontSize: '0.7rem', color: 'common.darkred', textTransform: 'uppercase' }}>{process.env.NODE_ENV}</Typography>
            )}
        </Container>
    );
}
